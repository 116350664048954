<template>
  <div class="warehousing-info">
    <div class="baseinfo">
      <div class="baseinfo-title">
        {{ $t('lang.gles.common.basicInfo') }}
      </div>
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="120"
        :extend-config="extendFormConfig"
        class="basic-data-add-form"
      >
        <template #externalParamsSlot>
          <el-tooltip placement="right">
            <div slot="content">
              <p v-for="(value, key, i) in expandObj" :key="key">
                {{ $t('lang.gles.common.expangField') }}{{ i + 1 }}：{{ value }}
              </p>
            </div>
            <el-button
              type="primary"
              :disabled="!Object.keys(expandObj).length"
            >
              {{ $t('lang.gles.common.fieldDetail') }}
            </el-button>
          </el-tooltip>
        </template>
      </m-form>
    </div>
    <div class="container-info">
      <div class="container-info_title">
        {{ $t('lang.gles.orderManage.materialDetail') }}
      </div>
      <!-- 有容器 -->
      <m-table
        v-if="containerTableData.length"
        border
        class="mb10"
        :table-item="containerTableItem"
        :table-data="containerTableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
      >
        <!-- 展开 -->
        <template v-slot:expand="scope">
          <div class="child-table">
            <m-table
              size="mini"
              :table-item="childContainerTableItem"
              :table-data="scope.row.materialDtList"
              :page-data="pageData"
            >
              <template #clickSlot="{ row }">
                <span
                  v-if="row.materialId"
                  class="list-detail-item"
                  @click="onAttr(row)"
                >
                  {{ $t('lang.gles.common.view') }}
                </span>
              </template>
            </m-table>
          </div>
        </template>
      </m-table>
      <!-- 无容器 -->
      <m-table
        v-if="noContainertableData.length"
        border
        :table-item="tableItem"
        :table-data="noContainertableData"
        :page-data="pageData"
      >
        <template #clickSlot="{ row }">
          <span
            v-if="row.materialId"
            class="list-detail-item"
            @click="onAttr(row)"
          >
            {{ $t('lang.gles.common.view') }}
          </span>
        </template>
      </m-table>
    </div>
    <batch-property-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :mode="mode"
      :init-row="batchPropertyRow"
      :list="batchPropertyDetail"
    />
  </div>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import BatchPropertyDialog from '../../stockAdjustmentOrder/components/batchPropertyDialog'
import {
  getEditBaseFormData,
  getDetailNoContainerTableItems,
  getDetailTableItems,
  getDetailChildTableItems
} from '../data'
import addMixins from '@/mixins/addMixins'
import { mapState } from 'vuex'
export default {
  components: {
    MForm,
    BatchPropertyDialog
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 批属性弹窗
      detailDialogVisible: false,
      batchPropertyRow: {},
      batchPropertyDetail: [],
      detailObj: {},
      noContainertableData: [],
      containerTableData: [],
      extendFormConfig: {
        isNeedBtn: false
      },
      tableExtendConfig: {
        sortNum: true,
        expand: true
      },
      pageData: null,
      expandObj: {}
    }
  },
  computed: {
    ...mapState('materialManage', ['materialTowardList']),
    ...mapState('base', ['abAxisList']),
    baseFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(
        this,
        { ...row, ...this.detailObj },
        this.options
      )
    },
    tableItem() {
      return getDetailNoContainerTableItems(this, this.options)
    },
    containerTableItem() {
      return getDetailTableItems(this, this.options)
    },
    childContainerTableItem() {
      return getDetailChildTableItems(this, this.options)
    }
  },
  created() {
    if (!this.materialTowardList.length) {
      this.$store.dispatch('materialManage/getMaterialToward')
    }
    if (!this.abAxisList?.length) {
      this.$store.dispatch('base/getAbAxisList')
    }
    this.getExternalStoreOutDetail()
  },
  methods: {
    async getExternalStoreOutDetail() {
      const params = {
        externalOutNo: this.rowDetail.externalOutNo
      }
      const { data } = await this.$httpService(
        this.$apiStore.orderManage('getExternalStoreOutDetail'),
        params
      )
      this.detailObj = data
      data.containerDtList &&
        data.containerDtList.forEach((item) => {
          item.materialDtList &&
            item.materialDtList.forEach((cItem) => {
              cItem.materialName = cItem.materialId
              cItem.materialDirectionName = this.getMaterialDirectionName(cItem)
            })
        })
      data.noContainerDtList &&
        data.noContainerDtList.forEach((item) => {
          item.materialName = item.materialId
          item.materialDirectionName = this.getMaterialDirectionName(item)
        })
      this.noContainertableData = data.noContainerDtList || []
      this.containerTableData = data.containerDtList || []
      const expand = data.goodsPositionExpand || {}
      Object.keys(expand).forEach((key) => {
        if (key.startsWith('extra')) {
          let val = expand[key] ?? ''
          if (key === 'extraParam8') {
            val =
              this.abAxisList.find((t) => t.value === Number(val))?.label ?? val
          }
          this.$set(this.expandObj, key, val)
        }
      })
    },
    getMaterialDirectionName(item) {
      return (
        this.materialTowardList.find((t) => t.value === item.materialDirection)
          ?.label ?? ''
      )
    },
    // 查看批属性
    async onAttr(row) {
      // console.log(row)
      const params = {
        id: row.materialId
      }
      this.batchPropertyRow = {}
      // row.materialBatchNo = 123
      const arr = [
        row.manufactureDate, // 生产日期
        row.expirationDate, // 过期日期
        row.materialBatchNo,
        row.batchProp1,
        row.batchProp2,
        row.batchProp3,
        row.batchProp4,
        row.batchProp5,
        row.batchProp6,
        row.batchProp7,
        row.batchProp8,
        row.batchProp9,
        row.batchProp10
      ]
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('queryMaterialDetail'),
        params
      )
      if (code) return
      this.batchPropertyDetail = data.batchPropertyDetail || []
      if (this.batchPropertyDetail.length) {
        this.batchPropertyDetail.forEach((item, index) => {
          this.batchPropertyRow[item.propertyLabelCode] = arr[index]
        })
        this.detailDialogVisible = true
      } else {
        this.$message.error(this.$t('lang.gles.orderManage.batchPropertyTips'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.baseinfo-title,
.container-info_title {
  margin-bottom: 15px;
  font-weight: 800;

  &::before {
    content: '';
    display: inline-block;
    height: 21px;
    width: 4px;
    border-radius: 4px;
    background: #409eff;
    margin-right: 10px;
    vertical-align: text-bottom;
  }
}
.child-table {
  padding-left: 130px;
  background: #eee;
  padding: 6px;
  padding-left: 130px;
  box-shadow: 0px 0px 7px 0px #ddd inset;
}
.el-table__expanded-cell {
  padding: 0 !important;
}
</style>
