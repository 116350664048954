<template>
  <div class="warehousing-task">
    <m-table
      border
      :table-item="tableItem"
      :table-data="tableData"
      :page-data="pageData"
    >
      <!-- 展开 -->
      <!-- <template v-slot:expand="scope">
        <div class="child-table">
          <m-table
            size="mini"
            :table-item="childTableItem"
            :table-data="scope.row.detailList"
            :page-data="pageData"
          />
        </div>
      </template> -->
    </m-table>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getTaskTableItems } from '../data'
import addMixins from '@/mixins/addMixins'

export default {
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeName: 'info',
      currentCom: '',
      tableData: [],
      extendFormConfig: {
        isNeedBtn: false
      },
      pageData: null,
      containerArchivesAllList: [],
      materialCodeList: []
    }
  },
  computed: {
    tableItem() {
      return getTaskTableItems(this, this.options)
    }
  },
  created() {
    this.listTaskDetail()
  },
  methods: {
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryMaterialOption'))
      if (code) return
      this.materialCodeList = data.map(item => { return { label: item.materialCode, value: item.id } })
    },
    // 所有容器编码
    async getContainerArchivesAllList() {
      const { code, data } = await this.$httpService(this.$apiStore.orderManage('getContainerArchivesAllList'))
      if (code) return
      this.containerArchivesAllList = data && data.map(item => { return { label: item.containerArchivesCode, value: item.id } })
    },
    async listTaskDetail() {
      const params = {
        businessNo: this.rowDetail.outNo,
        receiptNo: this.rowDetail.updownMaterialNo,
        receiptType: this.rowDetail.receiptType
      }
      const { data } = await this.$httpService(
        this.$apiStore.orderManage('listTaskDetail'),
        params
      )
      this.tableData = data || []
    },
    /**
     * 取消
     */
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'WarehousingOrderList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
